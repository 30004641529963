import React from 'react'

function Header() {
  return (
    <header className='sticky'>
      <nav class="bg-white border-gray-200 ">
        <div class="flex justify-between max-w-full">
          <a href="/" class="flex items-center justify-between">
            <img src="./assets/headerimg1.png" class="mr-3 h-28 w-[120px] hidden md:inline" alt="Mereb consulting Logo" />
            <img src="./assets/headerimg3.png" class="mr-3 h-[85px] " alt="Mereb consulting Logo" />
            <img src="./assets/headerimg2.png" class="h-28 w-[350px] hidden md:inline" alt="Mereb consulting Logo" />
          </a>
          <div class="md:hidden flex items-center lg:order-2">
            {/* <a href="#" class="text-gray-800 dark:text-white hover:bg-gray-50 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none dark:focus:ring-gray-800">Log in</a>
            <a href="#" class="text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">Get started</a> */}
            <button data-collapse-toggle="mobile-menu-2" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
              <span class="sr-only">Open main menu</span>
              <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
              <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
            </button>
          </div>
          <div class="hidden justify-center items-center w-full lg:flex lg:w-auto md:ml-[105px] md:mr-[200px]" id="mobile-menu-2">
            <ul class="flex flex-col mt-4 lg:flex-row lg:space-x-8 lg:mt-0 text-lg font-semibold text-red-600">
              <li>
                <a href="/" class="block py-2 pr-4 pl-3 rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 " aria-current="page">Home</a>
              </li>
              <li>
                <a href="/about" class="block py-2 pr-4 pl-3 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 ">About</a>
              </li>
              <li>
                <a href="/services" class="block py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0">Services</a>
              </li>
              <li>
                <a href="/projects" class="block py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 ">Projects</a>
              </li>
              <li>
                <a href="/contact" class="block py-2 pr-4 pl-3  border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-primary-700 lg:p-0 ">Contact</a>
              </li>
            </ul>
          </div>
          <img src="./assets/headerimg4.png" class="h-28 w-[120px] hidden md:inline" alt="Mereb consulting Logo" />

        </div>
      </nav>
    </header>
  )
}

export default Header